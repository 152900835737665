import axios from 'axios';
import { Message } from 'element-ui';
axios.defaults.timeout = 60000; //设置接口超时时间
axios.defaults.baseURL = process.env.VUE_APP_BASE_API; //根据环境设置基础路径/
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时候携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params })
      .then(res => {
        if (res.data.code && res.data.code !== 0 && res.data.code !== 2 && url.indexOf('user') === -1) {
          // Message.warning(res.data.msg);
          console.warn(`${url} ==> ${res.data.msg}`);
        }
        resolve(res);
      })
      .catch(err => {
        Message.error(err);
        console.error(`${url} ==> ${err}`);
        reject(err);
      });
  });
}
export function getNoCatch(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params
      })
      .then(({ data }) => {
        if (data.code !== 0) return Message.error(data.msg);
        resolve(data);
      })
      .catch(errMsg => Message.error(errMsg));
  });
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时候携带的参数]
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then(res => {
        if (res.data.code && res.data.code !== 0 && url.indexOf('user') === -1) {
          Message.warning(res.data.msg);
          console.warn(`${url} ==> ${res.data.msg}`);
        }
        resolve(res);
      })
      .catch(err => {
        Message.error(err);
        console.error(`${url} ==> ${err}`);
        reject(err);
      });
  });
}

export function postNoCatch(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then(({ data }) => {
        if (data.code !== 0) return Message.error(data.msg);
        resolve(data);
      })
      .catch(errMsg => Message.error(errMsg));
  });
}
