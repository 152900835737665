const pathRewrite = '/api'
let topic = {
    dataTopic: pathRewrite + '/data', // data公共类api
    imgeTopic: pathRewrite + '/imge', // 图片上传
    cpttTopic: pathRewrite + '/cptt', // 公司抬头信息管理
    deptTopic: pathRewrite + '/dept', // 部门信息管理
    stffTopic: pathRewrite + '/stff', // 员工信息管理
    roleTopic: pathRewrite + '/role', // 角色管理
    permTopic: pathRewrite + '/perm', // 权限管理
    userTopic: pathRewrite + '/user', // 用户管理
    apprTopic: pathRewrite + '/appr', // 审批管理
    leavTopic: pathRewrite + '/leav', // 请假管理
    acctTopic: pathRewrite + '/acct', // 账套管理
    crftTopic: pathRewrite + '/crft', // 工艺管理
    mtrlTopic: pathRewrite + '/mtrl', // 物料管理
    optnTopic: pathRewrite + '/optn', // 数据中心
    smplTopic: pathRewrite + '/smpl', // 样品工单
    prodTopic: pathRewrite + '/prod', // 产品信息
    docuTopic: pathRewrite + '/docu', // 相关文档
    podrTopic: pathRewrite + '/podr', // 成品采购
    modrTopic: pathRewrite + '/modr', // 物料采购
    pconTopic: pathRewrite + '/pcon', // 成品合同
    mconTopic: pathRewrite + '/mcon', // 采购合同
    ppotTopic: pathRewrite + '/ppot', // 成品出库
    ppinTopic: pathRewrite + '/ppin', // 成品入库
    mpotTopic: pathRewrite + '/mpot', // 物料出库
    mpinTopic: pathRewrite + '/mpin', // 物料入库
    psotTopic: pathRewrite + '/psot', // 成品出库单
    psinTopic: pathRewrite + '/psin', // 成品入库单
    msotTopic: pathRewrite + '/msot', // 物料出库单
    msinTopic: pathRewrite + '/msin', // 物料入库单
    pstkTopic: pathRewrite + '/pstk', // 成品库存表
    mstkTopic: pathRewrite + '/mstk', // 物料库存表
    mtqrTopic: pathRewrite + '/mtqr',//二维码信息
    mstfTopic: pathRewrite + '/mstf',//物料出入库
    podtTopic: pathRewrite + '/podt',//产品总控表
    openTopic: pathRewrite + '/rfid',//开卡总控表
    qmttTopic: pathRewrite + '/qmtt',//二维码金检
    macpTopic: pathRewrite + '/macp',//机检信息
    shweTopic: pathRewrite + '/shwe',//利器收发
    swstTopic: pathRewrite + '/swst',//利器套装管理
    mtrbTopic: pathRewrite + '/mtrb',//材料信息
    suppTopic: pathRewrite + '/supp',//供应商信息
    custTopic: pathRewrite + '/cust', // 客户信息
    purcTopic: pathRewrite + '/puca', // 材料订单
    dentTopic: pathRewrite + '/dent', // 送货单
    iqcrTopic: pathRewrite + '/iqcr', // 抽检记录
    fcreTopic: pathRewrite + '/fcre', // 工厂收货单
    mainTopic: pathRewrite + '/stck', // 物料库存
    factTopic: pathRewrite + '/fact', // 工厂采购
    mdrpTopic: pathRewrite + '/mdrp', // 工厂金检记录
    qrclTopic: pathRewrite + '/qrcl', // 二维码列表
    popuTopic: pathRewrite + '/popu', // 成品订单
    recordTopic: pathRewrite + '/record', // 断针记录
};
export default  topic;